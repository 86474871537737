.search-area {
    margin-bottom: 0px;
    
    button {
        text-decoration: none !important;
        color: var(--accent-color);
        background: none;
        display: inline;
        border: none;
        padding: 0px;
        transition: all 0.5s;
        border-top-right-radius: 2.5rem;
        border-bottom-right-radius: 2.5rem;

        &:hover {
            background-color: var(--accent-color);
        }
    }
}